export const tabList = ["返回对战", "游戏历史", "十佳对战"];
export const tabList2 = ["会员对战", "游戏历史", "十佳对战"];
export const searchList = [
  {
    name: "今日排行",
    type: 1,
  },
  {
    name: "昨日排行",
    type: 2,
  },
  // {
  //   name: "榜单奖励",
  //   type: 3,
  // },
];
import img1 from "../../assets/images/battle/s1.png";
import img2 from "../../assets/images/battle/s2.png";
import img3 from "../../assets/images/battle/s3.png";
export const imgSort = [img1, img2, img3];
export const tabStatusList = [
  // {
  //   title: "全部",
  //   status: "all",
  //   check: true,
  // },
  // {
  //   title: "对战中",
  //   status: 1,
  //   check: false,
  // },
  {
    title: "等待中",
    status: 0,
    check: true,
  },
  // {
  //   title: "已结束",
  //   status: 2,
  //   check: false,
  // },
];
export const dropRecord = [
  {
    id: 0,
    name: "所有记录",
  },
  {
    id: 1,
    name: "主播记录",
  },
  {
    id: 2,
    name: "官方记录",
  },
];
export const dropBattle = [
  {
    id: 0,
    name: "所有对战",
  },
  {
    id: 1,
    name: "主播对战",
  },
  {
    id: 2,
    name: "官方对战",
  },
];
export const popupTabList = [
  {
    type: 1,
    title: "全部",
  },
  {
    type: 2,
    title: "一夜暴富",
  },
  {
    type: 3,
    title: "天选之人",
  },
  {
    type: 4,
    title: "五五开",
  },
  {
    type: 5,
    title: "经典盲盒",
  },
];
export const list = [
  {
    status_alias: "进行中",
    type: 1,
    box_num: 4,
    status: 1,
    id: 2,
    total_bean: 5.9,
    game_arena_player: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
  },
  {
    status_alias: "等待中",
    status: 0,
    type: 2,
    box_num: 4,
    total_bean: 5.91,
    game_arena_player: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
  },
  {
    status_alias: "已结束",
    type: 2,
    status: 2,
    box_num: 4,
    total_bean: 5.9,
    game_arena_player: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
      {
        show: true,
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
  },
];
export const sortUserList = [
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
  {
    name: "大师兄",
    integral: 23423432,
    cover:
      "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
  },
];
export const tableRecordList3 = [
  {
    title: "结束时间",
    width: "25%",
    index: "time",
    center: "center",
  },
  {
    title: "玩家",
    width: "15%",
    index: "userList",
    center: "center",
    render: true,
  },
  {
    title: "类型",
    width: "10%",
    index: "type",
    center: "center",
    render: true,
  },
  {
    title: "回合",
    width: "5%",
    index: "count",
    center: "center",
  },
  {
    title: "盲盒信息@2",
    width: "30%",
    index: "boxList",
    render: true,
  },
  {
    title: "结果总额",
    width: "25%",
    index: "price",
    render: true,
  },
];

export const tableData = {
  total: 15,
  list: [
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
    {
      time: "07-04:11:11:11",
      userList: [
        {
          avatar:
            "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        },
      ],
      type: 1,
      count: 2,
      boxList: [
        {
          cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
        },
      ],
      price: 2.33,
    },
  ],
};

export const tenBoxList = [
  {
    type: 1,
    count: 2,
    userList: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        win: true,
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
    boxList: [
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
    ],
    price: 8888.88,
  },
  {
    type: 1,
    count: 2,
    userList: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        win: true,
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
    boxList: [
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
    ],
    price: 8888.88,
  },
  {
    type: 1,
    count: 2,
    userList: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        win: true,
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
    boxList: [
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
    ],
    price: 8888.88,
  },
  {
    type: 1,
    count: 2,
    userList: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        win: true,
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
    boxList: [
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
    ],
    price: 8888.88,
  },
  {
    type: 1,
    count: 2,
    userList: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        win: true,
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
    boxList: [
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
    ],
    price: 8888.88,
  },
  {
    type: 1,
    count: 2,
    userList: [
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
        win: true,
      },
      {
        avatar:
          "https://pic2.zhimg.com/v2-430e1a7dd0508a0b4b01dca9b94b22f5_b.webp?consumer=ZHI_MENG",
      },
    ],
    boxList: [
      {
        cover: "images/cf001757b1a97d87178fe842e216d7f0.png",
      },
    ],
    price: 8888.88,
  },
];

export const tenSearchPrice = [
  {
    name: "最大金额",
    id: 0,
  },
  {
    name: "最大增长",
    id: 1,
  },
  {
    name: "最佳欧皇",
    id: 2,
  },
];

export const tenSearchDay = [
  {
    name: "今日",
    id: 0,
  },
  {
    name: "昨日",
    id: 1,
  },
  {
    name: "近七日",
    id: 2,
  },
];
